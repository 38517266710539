let graphqlServerUrl              = "https://server.astralniakademie.cz/graphql";
let serverUrl                     = "https://server.astralniakademie.cz";
let mujTerapeutServerUrl          = "https://server.mujterapeut.cz";
let graphqlServerWsUrl            = "wss://server.astralniakademie.cz/graphql";
let webSocketServer               = "https://socketio.astralniakademie.cz";
let stripeKey                     = "pk_live_51IMH01KDfXXEpGN7wpPAMWSAz01l6OZva1ln1biIKJUhhrTM68ApldKxcCgNFJDUvJ4eEytNtNGj6hmQpyufAf0j00zhdDv8LW";

if (process && process.env && process.env.NODE_ENV === "development") {

    var localDomain = "localhost";
    localDomain = "192.168.0.187";

    graphqlServerUrl              = "http://" + localDomain + ":8902/graphql";
    serverUrl                     = "http://" + localDomain + ":8902";
    graphqlServerWsUrl            = "ws://" + localDomain + ":8902/graphql";
    webSocketServer               = "http://" + localDomain + ":8927";
    mujTerapeutServerUrl          = "http://" + localDomain + ":8906";
    stripeKey                     = "pk_test_51IMH01KDfXXEpGN7xS5YL104GFWnF5ZtJrGp9r9zCKYr9b4Sm6EBe2SSVYsfb9PtE0HcLpJcTisO1vrcj5To8mqQ00PcG4fFVY";

}

export const STREAM_URL                      = "zijinovy.laskyplnysvet.cz";

export const GRAPHQL_SERVER_URL              = graphqlServerUrl;
export const SERVER_URL                      = serverUrl;
export const MUJTERAPEUT_SERVER_URL          = mujTerapeutServerUrl;
export const GRAPHQL_SERVER_WS_URL           = graphqlServerWsUrl;
export const WEBSOCKET_SERVER                = webSocketServer;
export const STRIPE_PKEY                     = stripeKey;
